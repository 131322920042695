import { all } from "redux-saga/effects";
// import { cartSaga } from "./cart";
// import { wishlistSaga } from './wishlist';

export default function* rootSaga() {
  yield all([
    // cartSaga(),
    // wishlistSaga(),
  ]);
}
