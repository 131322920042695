import API from "api";
import { sendNotification } from "components/features/notification";
import Translate, { Texts } from "translate";

export const getFrontSettingRequest = async () => {
  try {
    const data = await API.Home.getFrontSetting();
    return data;
  } catch (error) {
    sendNotification(Translate(Texts.error), "error");
  }
};
export const getNewItems = async () => {
  try {
    const { success, messages, data } = await API.Home.getNewArrival();

    if (Array.isArray(messages) && messages.length > 0)
      messages.map((msg) => sendNotification(msg, "error"));
    return { success, messages, data };
  } catch (error) {
    sendNotification(Translate(Texts.error), "error");
  }
};
