import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import routes from "./routes";
import { Provider } from "react-redux";
import { makeStore } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import MainContextProvider from "contexts/main.context";
import { GlobalDebug } from "utils/remove-consoles";
const store = makeStore();

const isLocalhost = Boolean(
  !process.env.REACT_APP_CUSTOM_NODE_ENV ||
    process.env.REACT_APP_CUSTOM_NODE_ENV === "development" ||
    process.env.REACT_APP_CUSTOM_NODE_ENV === "staging"
);

function App() {
  useEffect(() => {
    // GlobalDebug(isLocalhost ?? false);
    document.documentElement.style.setProperty("--iconPhone", `"\\e806"`);
    document.documentElement.style.setProperty(
      "--iconCheckCircleO",
      `"\\e80b"`
    );
    document.documentElement.style.setProperty("--iconInfoCircle", `"\\e80c"`);
    document.documentElement.style.setProperty("--iconCog", `"\\e80d"`);
    document.documentElement.style.setProperty("--iconHome", `"\\e811"`);
    document.documentElement.style.setProperty("--iconCartPlus", `"\\e812"`);
    document.documentElement.style.setProperty("--iconRefresh", `"\\e813"`);
    document.documentElement.style.setProperty("--iconMapMarker", `"\\e815"`);
    document.documentElement.style.setProperty("--iconTwitter", `"\\f099"`);
    document.documentElement.style.setProperty("--iconPinterest", `"\\f0d2"`);
    document.documentElement.style.setProperty("--ionGoogle", `"\\f0d5"`);
    document.documentElement.style.setProperty("--iconLinkedin", `"\\f0e1"`);
    document.documentElement.style.setProperty("--iconAngleDown", `"\\f110"`);
    document.documentElement.style.setProperty("--iconAngleLeft", `"\\f111"`);
    document.documentElement.style.setProperty("--iconAngleRight", `"\\f112"`);
    document.documentElement.style.setProperty("--iconArrowUp", `"\\f122"`);
    document.documentElement.style.setProperty("--iconArrows", `"\\f123"`);
    document.documentElement.style.setProperty("--iconBars", `"\\f131"`);
    document.documentElement.style.setProperty("--iconYoutube", `"\\f167"`);
    document.documentElement.style.setProperty("--iconInstagram", `"\\f16d"`);
    document.documentElement.style.setProperty("--iconClose", `"\\f191"`);
    document.documentElement.style.setProperty("--iconEnvelope", `"\\f1c6"`);
    document.documentElement.style.setProperty("--iconHeartO", `"\\f234"`);
    document.documentElement.style.setProperty(
      "--iconLongArrowDown",
      `"\\f272"`
    );
    document.documentElement.style.setProperty(
      "--iconLongArrowLeft",
      `"\\f273"`
    );
    document.documentElement.style.setProperty(
      "--iconLongArrowRight",
      `"\\f274"`
    );
    document.documentElement.style.setProperty(
      "--iconQuestionCircle",
      `"\\f2cc"`
    );
    document.documentElement.style.setProperty("--iconRandom", `"\\f2d0"`);
    document.documentElement.style.setProperty("--iconSearch", `"\\f2eb"`);
    document.documentElement.style.setProperty(
      "--iconShoppingCart",
      `"\\f2fa"`
    );
    document.documentElement.style.setProperty("--iconUser", `"\\f364"`);
    document.documentElement.style.setProperty("--iconFacebookF", `"\\f39e"`);
  }, []);
  return (
    <Provider store={store}>
      <PersistGate
        loading={<i className="icon-refresh load-more-rotating" />}
        persistor={store.__persistor}
      >
        <MainContextProvider>
          <RouterProvider router={routes} />
        </MainContextProvider>
      </PersistGate>
    </Provider>
  );
}
export default App;
