/* eslint-disable no-console */
export const GlobalDebug = (function () {
  var savedConsole = console;
  /**
   * @param {boolean} debugOn
   */
  return function (debugOn) {
    // supress all type of consoles functionality
    if (debugOn) {
      console.log = savedConsole.log;
      console.info = savedConsole.info;
      console.warn = savedConsole.warn;
      console.error = savedConsole.error;
    } else {
      console.log = function () {};
      console.info = function () {};
      console.warn = function () {};
      console.error = function () {};
    }
  };
})();
