import Setting from "constants/setting";
import Request, { RequestHelper } from "../request";

const CartAPIs = {
  /**
   *
   * @param {Array} barCodes
   */ // calculate final price or ...  of cart items
  calculateTotal: (barCodes) =>
    Request({
      url: `/Cart/CalculateTotal?BarcodeList=${barCodes}`,
    }),

  /**
   *
   * @param {Array} barcodes
   * @param {String | Number} barcode
   */ // add a barcode to user cart
  addBarcodes: (barcode) => {
    return Request({
      url: "/Cart",
      method: "POST",
      isPrivate: true,
      body: { barcodes: barcode },
    });
  },
  setUserShoppingInfo: (data) => {
    const item = {
      name: data.name ?? "",
      startDate: data.startDate,
      notes: data.notes ?? "",
      company: data.company ?? "",
      showName: data.showName ?? "",
    };
    if (data.endDate) item.endDate = new Date(data.endDate);
    return Request({
      method: "PUT",
      url: "/Cart",
      isPrivate: true,
      body: item,
    });
  },
  // get all information about user shopping cart
  getUserShoppingInfo: () =>
    Request({
      url: "/Cart/SelectByUser?WBCollection=false&DefaultProductionOnly=false",
      isPrivate: true,
    }),
  // get loggedIn user cart items
  getUserCartItems: () => {
    return Request({
      url: "/Cart/SelectByUserProduction",
      isPrivate: true,
    });
  },
  // get cart items
  getCartItems: (barCodes) => {
    return Request({
      url: `/Cart/SelectByBarcode?BarcodeList=${barCodes}`,
    });
  },
  removeCartItem: (barCode) => {
    return Request({
      url: `/Cart/Delete`,
      method: "DELETE",
      body: { barcode: barCode },
      isPrivate: true,
    });
  },
  submitCart: () => {
    return Request({
      method: "PUT",
      url: "/Cart/SubmitRequest",
      isPrivate: true,
      body: {},
    });
  },
  sendEmail: (value, captcha) => {
    const body = {
      name: value.name,
      email: value.email,
      note: value?.note ?? "",
    };
    return Request({
      url: "/Cart/ShoppingCartSendEmail",
      method: "POST",
      body,
      captcha: captcha,
    });
  },
};

export default CartAPIs;
