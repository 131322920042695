import Setting from "constants/setting";
import { LOG } from "utils";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "utils/appManager";
import { sendNotification } from "components/features/notification";
import Translate, { Texts } from "translate";
import { addMinutes } from "utils/addMinutes";
import { actions } from "store/user";
import { makeStore } from "store";
const store = makeStore();

const baseUrl = process.env.REACT_APP_SERVER_URL ?? window.location.origin;

/**
 * fetch a request
 * @param {String} url
 * @param {"GET" | "POST"} method
 * @param {any} body
 */

export const Request = (props) => {
  if (props?.isPrivate) {
    if (
      new Date(getLocalStorage(Setting.refreshTokenExpireTime)) < new Date() &&
      new Date(getLocalStorage(Setting.tokenExpireTime)) > new Date()
    ) {
      return refreshToken(props);
    } else if (
      new Date(getLocalStorage(Setting.tokenExpireTime)) < new Date()
    ) {
      store.dispatch(actions.removeUser());
      return { success: false };
    } else {
      return callRequest(props);
    }
  } else return callRequest(props);
};
const callRequest = ({
  url,
  method = "GET",
  body = null,
  isPrivate = true,
  captcha = null,
  response = (res) => res.json(),
}) => {
  const token = getLocalStorage(Setting.userToken);

  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(`${baseUrl}${url}`, {
        body: body ? JSON.stringify(body) : null,
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: isPrivate ? token : undefined,
          HeaderRequest: captcha ? captcha : undefined,
        },
      });

      const result = await response(res);

      if (res.status === 403) {
        store.dispatch(actions.removeUser());
        return {};
      } else if (res.status !== 200) {
        sendNotification(Translate(Texts.error), "error");
        return {};
      }
      return resolve(result);
    } catch (err) {
      LOG(err);
      return reject(err);
    }
  });
};

const refreshToken = async (props) => {
  const token = getLocalStorage(Setting.userToken);

  const res = await fetch(`${baseUrl}/UserCode/RefreshToken`, {
    body: null,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  try {
    const { data } = await res.json();
    if (res.status === 403) {
      store.dispatch(actions.removeUser());
      return {};
    } else if (res.status !== 200) {
      sendNotification(Translate(Texts.error), "error");
      return {};
    } else {
      setLocalStorage(
        Setting.userToken,
        data.token,
        Boolean(getLocalStorage(Setting.rememberMe)) ? 60 * 24 : 0
      );
      setLocalStorage(
        Setting.tokenExpireTime,
        addMinutes(new Date(), data.loginExpire),
        Boolean(getLocalStorage(Setting.rememberMe)) ? 60 * 24 : 0
      );
      setLocalStorage(
        Setting.refreshTokenExpireTime,
        addMinutes(new Date(), data.loginExpire / 2),
        Boolean(getLocalStorage(Setting.rememberMe)) ? 60 * 24 : 0
      );
      return callRequest(props);
    }
  } catch (err) {
    LOG(err);
  }
};
export default Request;
