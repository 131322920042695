import Setting from "constants/setting";

const { default: Request } = require("../request");

export const searchType = {
  category: "CATEGORY",
  mainCategory: "MAIN_CATEGORY",
};

const Search = {
  /**
   * @param {String} text
   * @param {Number} perPage
   * @param {Number} page
   */
  searchByText: ({ text = "", perPage = Setting.itemsPerPage, page = 1 }) =>
    Request({
      url: `/SearchItem?TextToSearch=${text}&RowsPerPage=${perPage}&PageNumber=${page}`,
    }),
};

export default Search;
