import Request from "../request";

const Account = {
  getAccountInfo: (userName) => {
    return Request({
      url: `/UserCode/SelectAllByEmail?email=${userName}`,
      method: "GET",
      isPrivate: true,
    });
  },
  updateAccount: (value) => {
    const {
      subscribe,
      address1,
      address2,
      city,
      state,
      company,
      phone,
      firstName,
      lastName,
      zip,
    } = value;
    const body = {
      firstName: firstName,
      lastName: lastName,
      receiveemailupdate: subscribe == "yes" ? true : false,
      address1: address1 ?? "",
      address2: address2 ?? "",
      city: city ?? "",
      state: state ?? "",
      zip: zip ?? "",
      company: company ?? "",
      phone: phone ?? "",
      comment: "",
      isApproved: true,
    };

    return Request({
      url: `/Account/UpdateAccount`,
      method: "PUT",
      isPrivate: true,
      body,
    });
  },
  changeSecurityQuestion: (value) => {
    const { question, answer } = value;
    const body = {
      securityQuestion: question,
      securityAnswer: answer,
    };

    return Request({
      url: `/Account/ChangeSecurityPassword`,
      method: "PUT",
      body,
      isPrivate: true,
    });
  },
  changePassword: (value) => {
    const { passwordConfirm, password, oldPassword } = value;
    const body = {
      passwordConfirm: passwordConfirm,
      password: password,
      oldPassword: oldPassword,
    };

    return Request({
      url: `/Account/ChangePassword`,
      method: "PUT",
      body,
      isPrivate: true,
    });
  },
};

export default Account;
