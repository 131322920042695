import { Link, NavLink as ReactNavLink } from "react-router-dom";

export default function ALink({
  children,
  className,
  style,
  onClick,
  href = "#",
  ...props
}) {
  function defaultFunction(e) {
    if (href == "#") {
      e.preventDefault();
    }
    if (onClick) onClick();
  }

  return (
    <Link
      className={className}
      style={style}
      to={href}
      onClick={defaultFunction}
      {...props}
    >
      {children}
    </Link>
  );
}

const NavLink = ({ children, href, ...props }) => (
  <ReactNavLink to={href} {...props}>
    {children}
  </ReactNavLink>
);

export { NavLink };
