import Setting from "constants/setting";
import Request from "../request";

const Register = {
  register: (value, captcha) => {
    const {
      email,
      subscribe,
      address1,
      address2,
      city,
      state,
      company,
      phone,
      zip,
      ...data
    } = value;
    const body = {
      ...data,
      username: email,
      email: email,
      receiveemailupdate: subscribe == "yes" ? true : false,
      address1: address1 ?? "",
      address2: address2 ?? "",
      city: city ?? "",
      state: state ?? "",
      zip: zip ?? "",
      company: company ?? "",
      phone: phone ?? "",
      comment: "",
      returnValue: 0,
      isApproved: true,
      defaultproduction: Setting.defaultProduction,
    };

    return Request({
      url: `/UserCode/Register`,
      method: "POST",
      body,
      captcha: captcha,
    });
  },
};

export default Register;
