import Setting from "constants/setting";
import Request from "../request";

const CategoryPage = {
  /*
  <match url="search/([^/]+)/([^/]+)/([^/]+)/([^/]+)/([^/]+)/([^/]+)"/>
  <action type="Rewrite" 
  url="search?Gender={R:1}&amp;Circa={R:2}&amp;Category={R:3}&amp;broadcategory={R:4}&amp;Description={R:5}&amp;pageNumber={R:6}"
  appendQueryString="false"/>

  !!! but !!! backend said we should send just category
  */

  /**
   * @param {Array} params
   * @param {Number} perPage
   * @param {Number} page
   */
  getCategory: ({
    broadCategory,
    category,
    gender,
    circa,
    perPage = Setting.itemsPerPage,
    page = 1,
  }) =>
    Request({
      url: `/SearchItem?${
        category
          ? "Category=" +
            category +
            (circa && "&Circa=" + circa) +
            (gender && "&Gender=" + gender)
          : (circa && "&Circa=" + circa) + "&broadCategory=" + broadCategory
      }&RowsPerPage=${perPage}&PageNumber=${page}`,
    }),
  getCategoryList: () =>
    Request({
      url: "/Category/SelectAllByBroadCategory",
    }),
};

export default CategoryPage;
