import Request from "../request";

const Password = {
  verifyUser: (email, captcha) => {
    const body = {
      userName: email,
    };
    return Request({
      url: "/ResetPassword/VerifyUser",
      method: "POST",
      body,
      captcha: captcha,
    });
  },

  verifySecurityQuestion: ({ email, answer, question }) => {
    const body = {
      userName: email,
      answer: answer,
      question: question,
    };
    return Request({
      url: "/ResetPassword/SendLink",
      method: "POST",
      body,
    });
  },
  changePassword: (data) => {
    return Request({
      url: "/ResetPassword/ChangePassword",
      method: "Put",
      body: data,
    });
  },
  resetPasswordLink: (param) =>
    Request({
      url: "/ResetPassword" + param,
    }),
};

export default Password;
