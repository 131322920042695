import { getFrontSettingRequest } from "api/apiCall/home";
import { sendNotification } from "components/features/notification";
import { useEffect, createContext, useState, useContext } from "react";
import Translate, { Texts } from "translate";

const MainContext = createContext(null);

const MainContextProvider = ({ children }) => {
  const [mainData, setMainData] = useState(MainContext);

  const getDataFile = async () => {
    try {
      const data = await fetch("/json/data.json");
      const res = await data.json();
      setMainData((oldData) => ({ ...oldData, ...res }));
    } catch (err) {
      sendNotification(Translate(Texts.error), "error");
    }
  };
  const getSetting = async () => {
    const { data } = await getFrontSettingRequest();
    setMainData((oldData) => ({ ...oldData, setting: data }));
  };
  useEffect(() => {
    getDataFile();
    getSetting();
  }, []);
  return (
    <MainContext.Provider value={mainData}>
      {mainData && children}
    </MainContext.Provider>
  );
};
export default MainContextProvider;
export const useMainData = () => useContext(MainContext);
