const Texts = {
  home: "home",
  category: "category",
  search: "search",
  faq: "faq",
  forms: "forms",
  aboutUs: "aboutUs",
  contactUs: "contactUs",
  categories: "categories",
  reports: "reports",
  cartSubmitedSuccessfully: "cartSubmitedSuccessfully",
  loginOrRegister: "loginOrRegister",
  requestCartWasSuccessFullText: "requestCartWasSuccessFullText",
  login: "login",
  securityQuestionIsEmpty: "securityQuestionIsEmpty",
  cart: "cart",
  wishList: "wishList",
  gridView: "gridView",
  listView: "listView",
  securityQuestionUpdated: "securityQuestionUpdated",
  firstName: "firstName",
  yourName: "yourName",
  sendTo: "sendTo",
  lastName: "lastName",
  companyName: "companyName",
  address1: "address1",
  address2: "address2",
  city: "city",
  passwordAlreadyUsed: "passwordAlreadyUsed",
  state: "state",
  postalCode: "postalCode",
  email: "email",
  emailText: "emailText",
  fax: "fax",
  emailHint: "emailHint",
  phone: "phone",
  password: "password",
  passwordConfirm: "passwordConfirm",
  passwordRule: "passwordRule",
  subscribe: "subscribe",
  socialMedia: "socialMedia",
  yes: "yes",
  no: "no",
  totalWeeks: "totalWeeks",
  totalItems: "totalItems",
  loginToSubmit: "loginToSubmit",
  securityQuestion: "securityQuestion",
  emailButton: "emailButton",
  copyRight: "copyRight",
  answer: "answer",
  agreeToPrivacyPolicy: "agreeToPrivacyPolicy",
  privacyPolicy: "privacyPolicy",
  termsOfUse: "termsOfUse",
  RENTALTRACKER: "RENTALTRACKER",
  confirm: "confirm",
  rememberMe: "rememberMe",
  forgotPassword: "forgotPassword",
  createAccount: "createAccount",
  forgotPasswordTitle: "forgotPasswordTitle",
  resetPassword: "resetPassword",
  changePassword: "changePassword",
  answerTheQuestion: "answerTheQuestion",
  emailFormatError: "emailFormatError",
  homeSlideSubTitle_1: "homeSlideSubTitle_1",
  homeSlideTitle_1_1: "homeSlideTitle_1_1",
  homeSlideTitle_1_2: "homeSlideTitle_1_2",
  homeSlideButton_1: "homeSlideButton_1",
  homeSlideSubTitle_2: "homeSlideSubTitle_2",
  homeSlideTitle_2_1: "homeSlideTitle_2_1",
  homeSlideTitle_2_2: "homeSlideTitle_2_2",
  homeSlideButton_2: "homeSlideButton_2",
  freeShipping: "freeShipping",
  freeShippingDescription: "freeShippingDescription",
  monyBack: "monyBack",
  moneyBackDescription: "moneyBackDescription",
  support: "support",
  supportDescription: "supportDescription",
  updateAccount: "updateAccount",
  footer: "footer",
  category: "category",
  search: "search",
  emptyReport: "emptyReport",
  noItemAddedToCart: "noItemAddedToCart",
  account: "account",
  toggleMobileMenuText: "toggleMobileMenuText",
  noProductAddedToCart: "noProductAddedToCart",
  returnToShop: "returnToShop",
  noProductAddedToWishlist: "noProductAddedToWishlist",
  goShop: "goShop",
  weekly: "weekly",
  production: "production",
  newArrivals: "newArrivals",
  barcode: "barcode",
  weekly: "weekly",
  production: "production",
  viewSet: "viewSet",
  viewMore: "viewMore",
  quantity: "quantity",
  newArrivals: "newArrivals",
  barcode: "barcode",
  unavailable: "unavailable",
  menu: "menu",
  gender: "gender",
  photo: "photo",
  remove: "remove",
  add: "add",
  circa: "circa",
  size: "size",
  availability: "availability",
  available: "available",
  product: "product",
  emailThisItem: "emailThisItem",
  emailCart: "emailCart",
  to: "to",
  note: "note",
  send: "send",
  cancel: "cancel",
  addToCart: "addToCart",
  productionPackagePrice: "productionPackagePrice",
  weeklyPackagePrice: "weeklyPackagePrice",
  itemAddedToCart: "itemAddedToCart",
  itemRemovedFromCart: "itemRemovedFromCart",
  cartUpdatedSuccessfully: "cartUpdatedSuccessfully",
  noProductInCart: "noProductInCart",
  updateCart: "updateCart",
  total: "total",
  customerReviews: "customerReviews",
  downloading: "downloading",
  submitRequest: "submitRequest",
  totalWeekly: "totalWeekly",
  ourCustomers: "ourCustomers",
  totalProduction: "totalProduction",
  backToItems: "backToItems",
  noItemFound: "noItemFound",
  passwordUpdated: "passwordUpdated",
  filter: "filter",
  packages: "packages",
  ok: "ok",
  close: "close",
  contactFrom: "contactFrom",
  deleteCartItems: "deleteCartItems",
  removeAll: "removeAll",
  description: "description",
  deleteAll: "deleteAll",
  logout: "logout",
  welcome: "welcome",
  itemAlreadyisInYourCart: "itemAlreadyisInYourCart",
  code: "code",
  shoppingCartInformation: "shoppingCartInformation",
  beginOrPickupDate: "beginOrPickupDate",
  companyOROrganization: "companyOROrganization",
  EndORReturnDate: "EndORReturnDate",
  ShowName: "ShowName",
  save: "save",
  print: "print",
  sendEmail: "sendEmail",
  browseCategories: "browseCategories",
  error: "error",
  next: "next",
  allOrders: "allOrders",
  of: "of",
  prev: "prev",
  perPage: "perPage",
  showing: "showing",
  items: "items",

  myAccount: "myAccount",
  signIn: "signIn",
  viewCart: "viewCart",
  back: "back",
  myWishlist: "myWishlist",
  newPassword: "newPassword",
  updateButton: "updateButton",
  currentPassword: "currentPassword",
  accountInformation: "accountInformation",
  successfulRegisterMessage: "successfulRegisterMessage",
  ifYouHaveMoreDescription: "ifYouHaveMoreDescription",
  address: "address",
  register: "register",
  InvoiceDocument: "InvoiceDocument",
  CheckoutSheet: "CheckoutSheet",
  LossDamageReport: "LossDamageReport",
  OutstandingSheet: "OutstandingSheet",
  CheckinSheet: "CheckinSheet",
  reportPreview: "reportPreview",
  orderNumberTitle: "orderNumberTitle",
  productionTitle: "productionTitle",
  invoiceNumberTitle: "invoiceNumberTitle",
  startDate: "startDate",
  endDate: "endDate",
  accountInformationTitle: "accountInformationTitle",
  personalInformationTitle: "personalInformationTitle",
  securityInformationTitle: "securityInformationTitle",
  filterTitle: "filterTitle",
  itemAddedToWishList: "itemAddedToWishList",
  itemRemovedFromWishList: "itemRemovedFromWishList",
  pages: "pages",
  siteTabName: "siteTabName",
  rentalTracker: "rentalTracker",
  returnToSearchResult: "returnToSearchResult",
  showMeAllSearchResult: "showMeAllSearchResult",
  itemAlreadyisInYourWishList: "itemAlreadyisInYourWishList",
  goToWishList: "goToWishList",
  quickLinks: "quickLinks",
  removeFromWishlist: "removeFromWishlist",
  addToWishlist: "addToWishlist",
  shareOn: "shareOn",
};

export default Texts;
