import Texts from "./text.js";
import EnTextsJson from "./en/common.json" ;

/**
 *
 * @param {String} key
 */
const Translate = (key) => {
  return EnTextsJson[key]
};
export { Texts, Translate };
export default Translate;
