import Request from "../request";

const HomeAPIs = {
  getNewArrival: () =>
    Request({
      url: "/NewArrival",
    }),
  getFrontSetting: () =>
    Request({
      url: "/Setup/GetFrontSetting",
    }),
};

export default HomeAPIs;
