import Translate, { Texts } from "translate";
import { sendNotification } from "components/features/notification";

export const actionTypes = {
  addToWishlist: "ADD_TO_WISHLIST",
  removeFromWishlist: "REMOVE_FROM_WISHLIST",
  refreshStore: "REFRESH_WISH_LIST",
  mergeWithDatabase: "MERGE_WITH_DATABASE",
};

const initialState = {};

const wishlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.addToWishlist:
      let isExist = typeof state[action.payload.barcode] !== "undefined";
      if (!isExist) {
        return {
          ...state,
          [action.payload.barcode]: action.payload,
        };
      } else {
        sendNotification(Translate(Texts.itemAlreadyisInYourWishList));
      }
      return state;
    case actionTypes.removeFromWishlist:
      const { barcode } = action.payload;
      let isExistItem = typeof state[barcode] !== "undefined";
      if (isExistItem) {
        const newState = { ...state };
        delete newState[barcode];
        return {
          ...newState,
        };
      }
      return state;
    case actionTypes.mergeWithDatabase:
      const newItems = action.payload;
      const newState = {
        ...state,
      };
      newItems.forEach((item) => (newState[item.barcode] = item));

      return newState;
    case actionTypes.refreshStore:
      return initialState;

    default:
      return state;
  }
};

export const actions = {
  addToWishlist: (product) => ({
    type: actionTypes.addToWishlist,
    payload: product,
  }),

  removeFromWishlist: (product) => ({
    type: actionTypes.removeFromWishlist,
    payload: product,
  }),
  refreshWishlist: () => ({
    type: actionTypes.refreshStore,
  }),
  mergeWithDatabase: (newItems) => ({
    type: actionTypes.mergeWithDatabase,
    payload: newItems,
  }),
};

export default wishlistReducer;
