import Setting from "constants/setting";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getImageSrc } from "utils/getImageSrc";

const Image = ({
  alt = "",
  src,
  shouldShowNoImage,
  imageSize = Setting.medium,
  ...props
}) => (
  <LazyLoadImage
    alt={alt}
    src={
      shouldShowNoImage
        ? getImageSrc(src, imageSize)
        : window.location.origin + src
    }
    effect="blur"
    {...props}
  />
);

export default Image;
