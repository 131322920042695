import Request from "../request";

const Login = {
  login: (value, cart = []) => {
    let barCodes = "";
    if (cart?.length > 0) {
      cart?.forEach((item) => {
        barCodes += item?.barcode + ",";
      });
    }
    const body = {
      userName: value?.email,
      password: value?.password,
      barcodes: barCodes,
    };
    return Request({
      url: "/UserCode/Login",
      method: "POST",
      body,
    });
  },
};

export default Login;
