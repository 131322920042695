export function setLocalStorage(key, value, hours) {
  try {
    var now = new Date();
    now.setTime(now.getTime() + hours * 3600 * 1000);
    var cookie = `${key}=${value}; path=/;`;
    if (hours) cookie = cookie + `expires=${now.toUTCString()}`;
    document.cookie = cookie;
  } catch (error) {
    return null;
  }
}

export function removeLocalStorage(key) {
  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export function getLocalStorage(key) {
  try {
    if (typeof document !== "undefined") {
      var items = document?.cookie?.split(";");
      var value = items.find((i) => i.includes(key));
      if (value) return value?.replace(key + "=", "");
      return "";
    } else return null;
  } catch (error) {
    return null;
  }
}
