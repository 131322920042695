import Request from "../request";

const Report = {
  reportPermissions: () => {
    return Request({
      url: "/Report/GetReportPermission",
      isPrivate: true,
    });
  },
  reportProductions: (report) => {
    return Request({
      url: "/Report/GetProductions?Report=" + report,
      isPrivate: true,
    });
  },
  productionOrders: (production) => {
    return Request({
      url: "/Report/GetOrders?Production=" + production,
      isPrivate: true,
    });
  },
  productionInvoices: (production) => {
    return Request({
      url: "/Report/GetInvoices?Production=" + production,
      isPrivate: true,
    });
  },
  getReport: (value, report) => {
    let params = "?Production=" + value.production + "&Report=" + report;
    if (value.invoice) params += "&Invoice=" + value.invoice;
    else if (value.order) params += "&Orders=" + value.order;

    if (value.startDate) params += "&date1=" + value.startDate;
    if (value.endDate) params += "&date2=" + value.endDate;

    return Request({
      url: "/Report/GetReport" + params,
      isPrivate: true,
      response: (res) => res,
    });
  },
  getCartReport: (isPrivate, barCodes) => {
    let params = isPrivate ? "" : "?BarcodeList=" + barCodes;

    return Request({
      url: "/Report/GetShoppingCartReport" + params,
      method: "GET",
      response: (res) => res,
      isPrivate: isPrivate,
    });
  },
  getItemReport: (barCode) => {
    return Request({
      url: `/Report/GetPrintItem/${barCode}/1`,
      method: "GET",
      response: (res) => res,
    });
  },
};

export default Report;
