import { lazy } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import { searchType } from "api/urls/search";

const Home = lazy(() => import("pages/home"));
const Search = lazy(() => import("pages/search"));
const ErrorPage = lazy(() => import("pages/error-page"));
const Layout = lazy(() => import("components/layout"));
const Register = lazy(() => import("pages/register"));
const Itemdetail = lazy(() => import("pages/itemdetail"));
const FAQ = lazy(() => import("pages/faq"));
const Aboutus = lazy(() => import("pages/aboutus"));
const Contactus = lazy(() => import("pages/contactus"));
const Cart = lazy(() => import("pages/cart"));
const Login = lazy(() => import("pages/login"));
const Account = lazy(() => import("pages/account"));
const Wishlist = lazy(() => import("pages/wishlist"));
const ForgotPassword = lazy(() => import("pages/forgotpassword"));
const ChangePassword = lazy(() => import("pages/changepassword"));
const ReportPreview = lazy(() => import("pages/reportpreview"));
const Report = lazy(() => import("pages/report"));
const Forms = lazy(() => import("pages/forms"));
const PrivacyPage = lazy(() => import("pages/privacypolicy"));

const baseLayout = ({ children, ...props }) => ({
  path: "/",
  element: <Layout {...props} />,
  errorElement: <ErrorPage />,
  children: [...children],
});

const appName = process.env.REACT_APP_COMPANY_NAME;
const router = createBrowserRouter([
  baseLayout({
    children: [
      {
        path: "/",
        index: true,
        element: <Navigate to={"/" + appName?.replaceAll(" ", "_")} replace />,
      },
      {
        path: "/" + appName?.replaceAll(" ", "_"),
        element: <Home />,
      },
      {
        path: "/search",
        element: <Search />,
      },
      {
        path: "/search/:broadCategory",
        element: <Search />,
      },
      {
        path: "/search/:property/:broadCategory/:category/:all/:product",
        element: <Search searchBy={searchType.mainCategory} />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/itemdetail/:barcode/:slug",
        element: <Itemdetail />,
      },
      {
        path: "/faq",
        element: <FAQ />,
      },
      {
        path: "/aboutus",
        element: <Aboutus />,
      },
      {
        path: "/privacypolicy",
        element: <PrivacyPage />,
      },
      {
        path: "/contactus",
        element: <Contactus />,
      },
      {
        path: "/cart",
        element: <Cart />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/forms",
        element: <Forms />,
      },
      {
        path: "/forgotpassword",
        element: <ForgotPassword />,
      },
      {
        path: "/changePassword",
        element: <ChangePassword />,
      },
    ],
  }),
  baseLayout({
    children: [
      {
        path: "/account",
        element: <Account />,
      },

      {
        path: "/favorite",
        element: <Wishlist />,
      },
      {
        path: "/report",
        element: <Report />,
      },
      {
        path: "/reportpreview",
        element: <ReportPreview />,
      },
    ],
    isPrivate: true,
  }),
]);

export default router;
