import Home from "./urls/home";
import Category from "./urls/category";
import Item from "./urls/item";
import Cart from "./urls/cart";
import Search from "./urls/search";
import Register from "./urls/register";
import Login from "./urls/login";
import Wishlist from "./urls/wishlist";
import Password from "./urls/password";
import Report from "./urls/report";
import Account from "./urls/account";
import Contact from "./urls/contactUs";

const API = {
  Item,
  Home,
  Category,
  Cart,
  Search,
  Login,
  Report,
  Register,
  Wishlist,
  Password,
  Account,
  Contact,
};

export default API;
