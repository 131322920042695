export const actionTypes = {
  updateListType: "UPDATE_LIST_TYPE",
};

const initialState = { listType: "list" };

const listTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.updateListType:
      return { listType: action.payload };
    default:
      return state;
  }
};

export const actions = {
  updateListType: (type) => ({
    type: actionTypes.updateListType,
    payload: type,
  }),
};

export default listTypeReducer;
