import Request from "../request";

const Contact = {
  contactUs: (value, captcha) => {
    const values = {
      ...value,
      phone: value?.phone ?? "",
    };
    return Request({
      url: "/ContactUs",
      method: "POST",
      body: values,
      captcha: captcha,
    });
  },
};

export default Contact;
