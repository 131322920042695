
export const actionTypes = {
  addToCart: "ADD_TO_CART",
  removeFromCart: "REMOVE_FROM_CART",
  refreshStore: "REFRESH_STORE",
  updateCart: "UPDATE_CART",
  removeCarts:"REMOVE_ALL_CART_SAVED",
};

const initialCards = {
  data: [],
};

const cartReducer = (state = initialCards, action) => {
  switch (action.type) {
    case actionTypes.addToCart:
      var findIndex = state.data.findIndex(
        (item) => item.barcode == action.payload.barcode
      );
      if (findIndex === -1) return { data: [...state.data, action.payload] };
      return { data: [...state.data] };

    case actionTypes.removeFromCart:
      return {
        data: state.data.filter(
          (item) => item.barcode !== action.payload.barcode
        ),
      };

    case actionTypes.updateCart:
      return { data: [...action.payload] };
    case actionTypes.removeCarts:
      return { data: [] };

    case actionTypes.refreshStore:
      return { data: [] };

    default:
      return state;
  }
};

export const actions = {
  addToCart: (product) => ({
    type: actionTypes.addToCart,
    payload: product,
  }),

  removeFromCart: (product) => ({
    type: actionTypes.removeFromCart,
    payload: product,
  }),

  updateCart: (cartItems) => ({
    type: actionTypes.updateCart,
    payload: cartItems,
  }),

  removeCart: () => ({
    type: actionTypes.removeCarts,
    payload: "",
  }),
};

export default cartReducer;
