export const actionTypes = {
  setUserData: "SET_USER_DATA",
  removeUserData: "REMOVE_USER_DATA",
};

const initialState = { isLoggedIn: null };

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.setUserData:
      return { ...action.payload, isLoggedIn: !!action.payload };
    case actionTypes.removeUserData:
      return { isLoggedIn: false };
    default:
      return state;
  }
};

export const actions = {
  setUser: (data) => ({
    type: actionTypes.setUserData,
    payload: data,
  }),
  removeUser: () => ({
    type: actionTypes.removeUserData,
  }),
};

export default userReducer;
