const { default: Request } = require("../request");

const ItemUrls = {
  GetRecommended: () =>
    Request({
      url: "/ItemDetail/GetRecommendedDataByUser",
    }),
  itemInfo: (barcode) =>
    Request({
      url: "/SearchItem/GetDetail/" + barcode,
    }),
  sendEmail: (value, captcha) => {
    const body = {
      barcode: value.barcode,
      to: value.email,
      note: value?.note ?? "",
    };
    return Request({
      url: "/SearchItem/ItemDetailSendEmail",
      method: "POST",
      body,
      captcha: captcha,
    });
  },
};

export default ItemUrls;
