import Setting from "constants/setting";

const { default: Request } = require("api/request");

const WishlList = {
  /**
   *
   * @param {String | Number} barcode
   * @returns
   */
  add: (barcode) =>
    Request({
      url: "/Favorite",
      method: "POST",
      isPrivate: true,
      body: {
        barcode,
      },
    }),

  getAllAdded: () =>
    Request({
      url: `/Favorite/GetList`,
      isPrivate: true,
    }),
  /**
   *
   * @param {Number | String} barcode
   * @returns
   */
  delete: (barcode) =>
    Request({
      url: "/Favorite?Barcode=" + barcode,
      method: "DELETE",
      isPrivate: true,
    }),
};

export default WishlList;
