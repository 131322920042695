import Image from "components/partials/image";
import Setting from "constants/setting";
import { toast } from "react-hot-toast";
import Translate, { Texts } from "translate";
import ALink from "../alink";

/**
 *
 * @param {*} textKey
 * @param {"success" | "error" | "info" | "action"} type
 */
export const sendNotification = (
  textKey,
  type = "info",
  title,
  imageUrl,
  href,
  iconClass = "icon-cart-plus"
) => {
  // let arr = textKey?.split("<br/>");
  let el = textKey?.replaceAll("<br/>", "\n")?.replaceAll("<br>", "\n");

  switch (type) {
    case "action":
      // arr.forEach((el) => {
      toast((t) => (
        <div className="custom-toast-container">
          <div className="row">
            <Image
              imageSize={Setting.small}
              src={imageUrl}
              width={60}
              height={60}
              shouldShowNoImage
            />
            <p>{el}</p>
          </div>
          <ALink
            href={href}
            onClick={() => toast.dismiss(t.id)}
            className="btn btn-outline-primary-2 cart-btn"
          >
            {title}
            <i className={iconClass}></i>
          </ALink>
        </div>
      ));
      // });
      break;
    case "success":
      // arr.forEach((el) => {
      toast.success(el);
      // });
      break;
    case "error":
      // arr.forEach((el) => {
      toast.error(el);
      // });
      break;
    default:
      // arr.forEach((el) => {
      toast(el);
      // });
      break;
  }
};
