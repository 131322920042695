import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./root-saga";
import storage from "redux-persist/lib/storage";

// Import Reducers
import cartReducer from "./cart";
import wishlistReducer from "./wishlist";
import demoReducer from "./demo";
import categoryReducer from "./category";
import persistReducer from "redux-persist/es/persistReducer";
import userReducer from "./user";
import persistStore from "redux-persist/es/persistStore";
import Setting from "constants/setting";
import listTypeReducer from "./listType";

const rootReducers = combineReducers({
  cartlist: cartReducer,
  wishList: wishlistReducer,
  demo: demoReducer,
  category: categoryReducer,
  user: userReducer,
  listType: listTypeReducer,
});

const persistConfig = {
  key: Setting.persistKey,
  storage: storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducers);
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);
store.sagaTask = sagaMiddleware.run(rootSaga);
store.__persistor = persistStore(store);
export const makeStore = (context) => {
  return store;
};
// export default makeStore;
