export const PHASE_EXPORT = "phase-export";
export const PHASE_PRODUCTION_BUILD = "phase-production-build";
export const PHASE_PRODUCTION_SERVER = "phase-production-server";
export const PHASE_DEVELOPMENT_SERVER = "phase-development-server";
export const PHASE_TEST = "phase-test";

export const typesofItem = {
  weekly: "weeklyRate",
  production: "productionRate",
};

const Setting = {
  typeofItems: typesofItem.weekly,
  isLoggedIn: "IS_LOGGED_IN",
  itemsPerPage: 10,
  userSavedBarcodesKey: "USER_BARCODES_SAVED",
  rememberMe: "REMEMBER_ME",
  userToken: "USER",
  tokenExpireTime: "Token_EXPIRE_TIME",
  refreshTokenExpireTime: "REFRESH_TOKEN_EXPIRE_TIME",
  persistKey: "root",
  sampleUserId: "346750aa-9724-11ed-a8fc-0242ac120002",
  //imageSze
  large: "Large",
  small: "Small",
  medium: "Medium",
};
export default Setting;
